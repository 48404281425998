.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MapLegend {
	display: flex;
	flex-direction: row;
	width: 400px;
	height: 6px;
	font-size: 8px;
}

.MapLegendColor {
	flex: 1;
}

.MapLegendText {
	top: 5px;
	position: relative;
}

.ToolTip {
  color: black;
  background-color: white;
  border: 1px solid #ededed;
  opacity: 0.9;
  font-family: sans-serif;
}

h2 {
  font-size: 14px; 
  height:50px;
}

.light-yellow {
  fill: #ffffcc !important;
}

.yellow {
  fill: #ffeda0 !important;
}

.dark-yellow {
  fill: #fed976 !important;
}

.light-orange {
  fill: #feb24c !important;
}

.orange {
  fill: #fd8d3c !important;
}

.purple {
  fill: #b614cc 
}

.light-red {
  fill: #fc4e2a !important;
}

.red {
  fill: #e31a1c !important;
}

.dark-red {
  fill: #bd0026 !important;
}

.dark {
  fill: #800026 !important;
}

.marks path {
  stroke: none;
  stroke-width: 0;
  fill: none;
}

.cocMarks path {
  fill: none;
  stroke-width: 0.5;
  stroke: black;
}

.stateMarks path {
  stroke: rgb(31, 30, 30);
  stroke-width: 0.1;
  fill: none
}